import { ReactNode } from 'react';
import { Modal } from 'components/Modal';
import { Loader } from 'components/Loader';
import { BasicLoader } from 'components/BasicLoader';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ParticlesBg } from 'components/Particles';
import './FullScreenLayout.scss';

interface Props {
  children: ReactNode;
}

const FullScreenLayout = ({ children }: Props) => (
  <ErrorBoundary>
    <Loader>
      <BasicLoader />
    </Loader>
    <Modal />
    <ParticlesBg />
    <div className="full-screen-layout">{children}</div>
  </ErrorBoundary>
);

export { FullScreenLayout };
