import { isArray, isEmpty } from 'lodash';

export const toQueryString = (object: {
  [key: string]: string | number | (string | number)[];
}) => {
  let queryString = '';

  for (let key in object) {
    if (!isArray(object[key])) {
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
        object[key] as string | number
      )}&`;
    } else {
      for (let value of object[key] as (string | number)[]) {
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}&`;
      }
    }
  }

  return queryString.slice(0, queryString.length - +!isEmpty(object));
};
