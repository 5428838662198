import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer
    },
    container: {
      backgroundColor: theme.palette.background.paper,
      width: 478,
      maxWidth: '100%',
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(7),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%'
      }
    },
    title: {
      textTransform: 'uppercase',
      letterSpacing: '6px',
      marginTop: theme.spacing(6),
      fontWeight: 'bold'
    },
    caption: {
      marginTop: theme.spacing(2),
      color: theme.palette.text.primary
    },
    headerContainer: {
      marginBottom: theme.spacing(3),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: theme.palette.text.primary
    },
    closeIcon: {
      fontSize: 30
    }
  })
);

export { useStyles };
