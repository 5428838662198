import jwtDecode from 'jwt-decode';

const TOKEN = 'token';

export const setToken = (token: string) => {
  window.localStorage.setItem(TOKEN, token);
};

export const getToken = (): string => window.localStorage.getItem(TOKEN) || '';

export const decodeToken = (token: string): any => jwtDecode(token);

export const removeToken = () => window.localStorage.removeItem(TOKEN);
