import {
  createStyles,
  makeStyles,
  withStyles,
  Theme
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      width: 280,
      maxWidth: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        width: '280px'
      }
    },
    previewContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%'
    },
    avatar: {
      width: 52,
      height: 52,
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.up('lg')]: {
        width: 52,
        height: 52
      }
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    imageProfileMenu: {
      width: '100%',
      height: '100%',
      borderRadius: 7
    },
    buttonChangePassword: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: '#000'
      }
    },
    homeButton: {
      justifyContent: 'flex-start',
      marginLeft: -5,
      '& .text': {
        transform: 'translateY(1px)'
      },
      marginBottom: theme.spacing(2)
    },
    avatarWrapper: {
      marginLeft: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1)
      },
      borderRadius: '50%'
    },
    avatarBadge: {
      border: `3px solid ${theme.palette.common.white}`,
      width: 15,
      height: 15,
      borderRadius: '50%',
      transform: 'scale(1)',
      [theme.breakpoints.down('md')]: {
        border: `2px solid ${theme.palette.common.white}`,
        width: 11,
        height: 11
      }
    },
    iconAdmin: {
      color: '#fff'
    }
  })
);

const menuItemWithStyles = (Component: any) =>
  withStyles((theme: Theme) => ({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }))(Component);

export { useStyles, menuItemWithStyles };
