import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2)
    }
  },
  submitError: {
    textAlign: 'center',
    color: theme.palette.warning.light,
    fontSize: theme.typography.body2.fontSize
  },
  submitButton: {
    '& p': {
      fontWeight: 500,
      color: theme.palette.common.white
    }
  },
  sectionSpacer: {
    height: theme.spacing(2)
  },
  innerSectionSpacer: {
    height: theme.spacing(1)
  }
}));

export { useStyles };
