import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { Config } from './models';
import { useModal } from './hooks/useModal';
import { useStyles } from './ModalStyles';

const Modal = () => {
  const classes = useStyles();
  const { modalState, handleCloseModal } = useModal();

  const {
    show = false,
    title,
    body,
    actionButtons,
    isDismissible,
    configProps = {} as Config
  } = modalState;

  const {
    maxWidth,
    className,
    titleClassName,
    showDividers,
    scroll,
    ...otherProps
  } = configProps;

  return (
    <Dialog
      open={show}
      aria-labelledby="app-modal"
      onClose={handleCloseModal}
      maxWidth={maxWidth}
      disableBackdropClick={isDismissible ? undefined : true}
      disableEscapeKeyDown={isDismissible ? undefined : true}
      scroll={scroll}
      className={classes.dialog}
      {...otherProps}
    >
      {title && <DialogTitle className={titleClassName}>{title}</DialogTitle>}

      <DialogContent dividers={showDividers} className={className}>
        {body}
      </DialogContent>

      {actionButtons && <DialogActions>{actionButtons}</DialogActions>}
    </Dialog>
  );
};

export { Modal };
