import { createContext, Context } from 'react';
import { Auth, AuthContextType } from 'models/auth';

export const initialState: Auth = {
  isLogguedIn: false,
  token: '',
  user: {
    _id: '',
    name: '',
    email: '',
    password: '',
    birthday: '',
    location: {
      _id: '',
      code: '',
      name: ''
    },
    createAt: ''
  }
};

export const AuthContext: Context<AuthContextType> = createContext({
  auth: initialState
});
