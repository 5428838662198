import { createContext, Context } from 'react';
import { defaultTranslations } from './translations/default';
import { LoaderContextType } from './models';

const initialState: LoaderContextType = {
  show: false,
  translations: defaultTranslations,
  handleShowLoader: (show: boolean) => console.log('Loader', show)
};

export const LoaderContext: Context<LoaderContextType> = createContext(
  initialState
);
