import { makeStyles } from '@material-ui/core';
import { hexToRgba } from '../helpers/colors';

export const useStyles = makeStyles((theme) => ({
  menu: {
    border: `1px solid ${hexToRgba(
      theme.palette.text.primary,
      theme.palette.type === 'dark' ? 0.075 : 0.1
    )}`,
    boxShadow: theme.shadows[3]
  }
}));
