import { useState, useMemo, useEffect, ReactNode } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme, Theme } from '@material-ui/core/styles';
import { getItem } from 'utils/persistentStorage';
import { SideMenuContext as SideMenuContextType } from 'models/side_menu';
import { SideMenuContext } from './SideMenuContext';

interface Props {
  children: ReactNode;
}

const SideMenuProvider = ({ children }: Props) => {
  const theme: Theme = useTheme();
  const defaultMini = Boolean(getItem('mini_menu')) ?? true;

  const [visible, setVisible] = useState<boolean>(false);
  const [mini, setMini] = useState<boolean>(defaultMini);
  const [expanded, setExpanded] = useState<boolean>(!defaultMini);
  const smallScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (smallScreen) {
      setMini(false);
      setExpanded(true);
    } else {
      // const defaultMini = getItem('mini_menu') ?? true;

      setMini(defaultMini);
      setExpanded(!defaultMini);
    }
  }, [smallScreen]);

  const contextValue: SideMenuContextType = useMemo(
    () => ({
      visible,
      mini,
      expanded,
      setVisible,
      setMini,
      setExpanded
    }),
    [visible, mini, expanded]
  );

  return (
    <SideMenuContext.Provider value={contextValue}>
      {children}
    </SideMenuContext.Provider>
  );
};

export { SideMenuProvider };
