import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import { hexToRgba } from '../helpers/colors';

const useStyles = makeStyles<any, { color: string }>((theme) => ({
  container: {
    display: 'flex',
    overflowX: 'auto',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1)
  },
  uploadImageButton: {
    backgroundColor: hexToRgba(theme.palette.text.primary, 0.07),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    minWidth: 90,
    height: 90,
    minHeight: 90,
    cursor: 'pointer',
    position: 'relative',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      '& $uploadIcon': {
        transform: 'translateY(-8px)'
      },
      '& $uploadText': {
        opacity: 1,
        marginBottom: -12
      }
    },
    '&.disabled': {
      cursor: 'auto',
      backgroundColor: hexToRgba(theme.palette.text.primary, 0.05),
      '& $uploadMoreIcon': {
        color: hexToRgba(theme.palette.text.primary, 0.5)
      }
    }
  },
  uploadIcon: ({ color }) => ({
    fontSize: 45,
    transition: theme.transitions.create('transform'),
    color: _.get(theme.palette, color)
  }),
  uploadText: ({ color }) => ({
    fontSize: '0.7rem',
    position: 'absolute',
    bottom: '25px',
    opacity: 0,
    transition: theme.transitions.create(['margin-bottom', 'opacity']),
    color: _.get(theme.palette, color),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '95%'
  }),
  fileInput: {
    display: 'none'
  },
  uploadMoreIcon: ({ color }) => ({
    fontSize: 35,
    transition: theme.transitions.create('color'),
    color: _.get(theme.palette, color)
  })
}));

export { useStyles };
