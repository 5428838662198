import { get } from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Images } from 'assets';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme: Theme) => {
  const getSideMenu = get(theme, 'mixins.getSideMenu');

  return createStyles({
    drawer: ({ expanded }: any) => ({
      '& .MuiPaper-root': {
        ...getSideMenu(!expanded),
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard
        })
      }
    }),
    listSubHeader: ({ expanded }) => ({
      ...theme.typography.caption,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      paddingLeft: 36,
      letterSpacing: '1.1px',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      opacity: expanded ? 1 : 0,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.standard * 1.5
      })
    }),
    listItemText: ({ expanded }) => ({
      opacity: expanded ? 1 : 0,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.standard
      }),
      whiteSpace: 'nowrap'
    }),
    listItemIcon: ({ expanded }) => ({
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.standard
      }),
      transform: expanded ? 'none' : 'scale(1.2)',
      color: theme.palette.text.secondary,
      minWidth: 'auto',
      marginRight: theme.spacing(1.5),
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
        opacity: 0.9
      }
    }),
    listItem: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: 36,
      '&.active': {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.07),
        '& *': {
          color: theme.palette.primary.main,
          fontWeight: '500'
        },
        borderRight: `3px solid ${hexToRgba(theme.palette.primary.main, 0.5)}`
      },
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(1)
    },
    point: ({ expanded }) => ({
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[500],
      transition: theme.transitions.create(['margin', 'transform'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
      }),
      marginLeft: expanded ? theme.spacing(2) : '7px',
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        transform: 'scale(1.5)'
      }
    }),
    expandIcon: ({ expanded }) => ({
      opacity: expanded ? 1 : 0,
      transform: 'rotateZ(360deg)',
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.standard
      }),
      '&.inverted': {
        transform: 'rotateZ(180deg)'
      }
    }),
    boldText: {
      fontWeight: 500
    },
    logoContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      margin: theme.spacing(1),
      marginTop: 12,
      marginLeft: 6,
      padding: theme.spacing(1),
      paddingTop: 12,
      paddingLeft: 6,
      borderRadius: theme.shape.borderRadius * 2,
      justifyContent: 'flex-start'
    },
    iconLogo: ({ expanded }) => ({
      width: 44,
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.standard
      }),
      transform: expanded ? 'none' : 'scale(1.2) translateX(12px)'
    }),
    nameLogo: ({ expanded }) => ({
      width: 100,
      height: 44,
      marginLeft: theme.spacing(1),
      marginBottom: -5,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.standard
      }),
      opacity: expanded ? 1 : 0,
      maskImage: `url("${Images.nameLogo}")`,
      maskSize: '100%',
      maskRepeat: 'no-repeat',
      maskPosition: 'center',
      backgroundColor: theme.palette.text.primary
    })
  });
});

export { useStyles };
