import { createContext, Context } from 'react';
import { DrawerProps, DrawerContext as DrawerContextType } from 'models';

export const initialState: DrawerProps = {
  show: false,
  title: '',
  caption: '',
  body: null,
  closeButton: false,
  isDismissible: true,
  configProps: {
    anchor: 'right',
    variant: 'temporary',
    transitionDuration: 500
  }
};

export const DrawerContext: Context<DrawerContextType> | any = createContext(
  null
);
