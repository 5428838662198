import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      maxWidth: 500
    },
    link: {
      color: '#ccffdc',
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      '& span': {
        margin: theme.spacing(1)
      },
      '&:hover': {
        cursor: 'pointer',
        color: '#fff'
      }
    },
    legals: {
      position: 'relative',
      columnGap: 20,
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.spacing(8),
      marginBottom: 50
    },
    legalsLink: {
      textDecoration: 'none',
      color: '#ccffdc',
      '&:hover': {
        color: '#fff'
      }
    },
    summary: {
      color: '#ccffdc'
    },
    summaryIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 30,
      paddingTop: 5
    },
    imgIcon: {
      width: window.screen.width < 768 ? 50 : 80
    },
    iconWrapper: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    },
    iconTitle: {
      fontSize: window.screen.width < 768 ? 12 : 14
    },
    summaryTitle: {
      fontSize: 23,
      fontWeight: 'bold',
      textAlign: 'center',
      margin: 0
    },
    summarySubTitle: {
      fontStyle: 'italic',
      textAlign: 'center',
      fontSize: 16,
      margin: 20
    },
    imgTogether: {
      height: 80,
      margin: 'auto',
      left: 0,
      right: 0,
      position: 'relative',
      alignItems: 'center',
      display: 'block',
      marginTop: 20,
      marginBottom: 20
    }
  })
);

export { useStyles };
