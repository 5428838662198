import _ from 'lodash';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles<any, { color: string }>((theme) => ({
  container: {
    position: 'relative',
    transition: theme.transitions.create(['opacity', 'margin-right']),
    opacity: 0,
    marginRight: 0,
    '&.visible': {
      opacity: 1,
      marginRight: theme.spacing(0.5)
    }
  },
  avatar: {
    transition: theme.transitions.create('width'),
    height: 90,
    width: 0,
    '&.visible': {
      width: 90
    }
  },
  removeButton: ({ color }) => ({
    position: 'absolute',
    zIndex: 1,
    right: 9,
    top: 9 - theme.spacing(0.5),
    width: 15,
    height: 15,
    backgroundColor: _.get(theme.palette, color)
  }),
  removeIcon: ({ color }) => ({
    color: theme.palette.getContrastText(_.get(theme.palette, color)),
    fontSize: 13
  })
}));

export { useStyles };
