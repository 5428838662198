import React, { FC } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';

interface Props {
  fieldStatus: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
}

const PasswordAdornment: FC<Props> = (props) => {
  const { fieldStatus, onClick, onMouseDown } = props;

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onClick}
        onMouseDown={onMouseDown}
        edge="end"
      >
        {fieldStatus ? (
          <VisibilityIcon color="primary" fontSize="small" />
        ) : (
          <VisibilityOffIcon fontSize="small" />
        )}
      </IconButton>
    </InputAdornment>
  );
};

PasswordAdornment.defaultProps = {
  onClick: () => {},
  onMouseDown: () => {},
  fieldStatus: false
};

export { PasswordAdornment };
