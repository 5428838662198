export const es = {
  openMenu: 'Abrir menu',
  openModal: 'Abrir Modal',
  goToHome: 'Ir a la página de inicio',
  user: 'Usuario',
  page: 'página',
  profile: 'Perfil',
  logout: 'Cerrar Sesión',
  changePassword: 'Cambiar Contraseña',
  add: 'Agregar',
  edit: 'Editar',
  forms: 'Formularios',
  tables: 'Tablas',
  hooks: 'Hooks',
  install: 'Instalar',
  installApp: 'Instalar App',
  cancel: 'Cancelar',
  features: 'Características',
  description: 'Descripción',
  export: 'Exportar',
  accept: 'Aceptar',
  updateData: 'Actualizar datos',
  save: 'Guardar',
  search: 'Buscar',
  select: 'Selecciona...',
  writeYourSearch: 'Escribe tu búsqueda',
  associate: 'Asociar',
  unexpectedError: 'Error',
  send: 'Enviar',
  home: 'Inicio',
  start: 'Iniciar',
  selectPixel:
    'Tu oportunidad de vivir la experiencia con Heineken® 0.0 y la F1® está a un solo click.',
  selectPixelStep2: 'Descubre cuál de estos pixeles te hará ganar',
  goodLock:
    '¡Suerte! Estos son los premios que podrás encontrar dentro de los pixeles',
  congrats: '¡Felicitaciones!',
  termsOfUse: 'Términos y condiciones',
  privacyPolicy: 'Aviso de privacidad',
  acceptTermsOfUse: 'Acepto Términos y condiciones',
  favoriteBeerQuestion: '¿Cuál es tu cerveza favorita?',
  whenYouDrinkQuestion: {
    title: '¿Cuándo prefieres tomar una Heineken®?',
    option1: 'Durante la UCL',
    option2: 'Al ver el Gran Premio F1®',
    option3: 'Con amigos en un bar/restaurante',
    option4: 'Jugando en línea'
  },
  httpErrors: {
    error_token_invalid:
      'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.'
  },
  noData: {
    loading: 'Cargando datos',
    noDataToShow: 'No hay datos para mostrar',
    noResults: 'No se encontraron resultados para esa búsqueda'
  },
  form: {
    errors: {
      required: 'Este campo es requerido',
      email: 'Ingresa un correo electrónico valido',
      greaterThanOrEqualTo1: 'Debe ser mayor o igual a 1',
      passwordsDoNotMatches: 'Las contraseñas no coinciden',
      invalidFormat: 'Formato no valido',
      invalidDay: 'Día no valido',
      invalidMonth: 'Mes no valido',
      invalidYear: 'Año no valido',
      invalidPhone: 'Teléfono no valido'
    },
    imagePicker: {
      upload: 'Seleccionar',
      remove: 'Quitar'
    },
    inputs: {
      name: {
        label: 'Nombre',
        placeholder: 'Ingresa el nombre'
      },
      fullName: {
        label: 'Nombre completo',
        placeholder: 'Ingresa el nombre completo'
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Ingresa el correo electrónico'
      },
      image: {
        label: 'Seleccionar'
      },
      age: {
        label: 'Edad',
        placeholder: 'Ingresa tu edad'
      },
      address: {
        label: 'Dirección',
        placeholder: 'Ingresa tu dirección'
      },
      password: {
        label: 'Contraseña',
        placeholder: 'Contraseña'
      },
      confirmPassword: {
        label: 'Confirmar contraseña',
        placeholder: 'Confirmar contraseña'
      },
      personalDescription: {
        label: 'Descripción personal',
        placeholder: 'Ingresa una descripcion personal breve'
      },
      location: {
        label: 'Ubicación',
        placeholder: 'Elige tu ciudad'
      },
      day: {
        label: 'DD',
        placeholder: 'Día'
      },
      month: {
        label: 'MM',
        placeholder: 'Mes'
      },
      year: {
        label: 'YYYY',
        placeholder: 'Año'
      },
      verificationCode: {
        label: 'Código de verificación',
        placeholder: 'Código'
      },
      phone: {
        label: 'Teléfono',
        placeholder: '5500000000'
      },
      gender: {
        label: 'Género',
        placeholder: 'Elige tu generation'
      }
    }
  },
  routes: {
    appName: 'Heineken - Pixels',
    login: 'Login',
    register: 'Crear cuenta',
    passwordReset: 'Restablecer contraseña',
    error404: 'Pagina no encontrada',
    accountVerify: 'Verificar cuenta'
  },
  language: {
    es: 'Español',
    en: 'Ingles'
  },
  theme: {
    changeToDark: 'Cambiar a modo Obscuro',
    changeToLight: 'Cambiar a modo Claro'
  },
  sideMenu: {
    miniMode: 'Usar modo mini',
    listOfModule: 'Lista de {{module}}',
    createModuleItem: 'Crear {{module}}'
  },
  errorPages: {
    unexpected: 'Ha ocurrido un error inesperado',
    unexpectedDescription:
      'Lo sentimos, estaremos trabajando para resolver este error inesperado',
    notFound: 'Página no encontrada',
    notFoundDescription:
      'La página no existe o el contenido ya no está disponible'
  },
  pwaInstaller: {
    installError:
      'Lamentablemente, la función de instalación no es compatible con su navegador.',
    installWebApp: 'Instalar Web App',
    description:
      'Al instalar la aplicación podrás acceder más rápido sin necesidad de abrir tu navegador.',
    installSuccess: 'La aplicación se instaló correctamente en el dispositivo.',
    feature01: 'Acceso desde launcher.',
    feature02: 'Ingresa más rápido.'
  },
  auth: {
    signIn: 'Login',
    signOut: 'Cerrar Sesión',
    accountVerify: 'Verificar Cuenta',
    signTitle: '¡Hola, bienvenido!',
    signUp: 'Crear cuenta',
    forgotPassword: 'Olvidé mi contraseña',
    notHaveAccount: '¿No tienes una cuenta?',
    recoverPassword: 'Recuperar contraseña',
    recoverPasswordInstructions:
      'Descuida. Te enviaremos un email con el enlace para cambiar la contraseña de tu cuenta.',
    recoveryPasswordEmailSended:
      'Te hemos enviado un correo con las instrucciones para recuperar tu contraseña.',
    newPassword: 'Nueva contraseña',
    resetPassword: 'Restablecer contraseña',
    resetPasswordSuccess:
      'Su contraseña se ha restablecido con éxito, ahora puede iniciar sesión.',
    resetPasswordTimeExpired:
      'El tiempo para restablecer su contraseña ha expirado, inténtelo de nuevo.',
    resetPasswordInstructions:
      'Escribe una nueva contraseña para poder acceder a la plataforma.',
    alreadyHaveAccount: '¿Ya tienes una cuenta?',
    registerNextSteps:
      'Te hemos enviado un correo con las instrucciones para que puedas iniciar sesión',
    incompleteData: 'Datos incompletos',
    incompleteDataText: 'Debes aceptar los términos y condiciones',
    summary: {
      p1:
        '¡Sé parte de la develación del mural F1® Gran Carrera de México 2022!',
      span: 'Con Heineken® 0.0 podrás ganar'
    },
    successAccountVerify:
      'Tu cuenta ha sido verificada, ya puedes iniciar sesión'
  },
  intro: {
    title: 'Regístrate, elige tu pixel',
    win: '¡y gana!',
    description: `Descubre la colaboración de Ely Ely con Heineken®0.0`,
    subDescription: `El arte oficial de Heineken® para el domingo de la carrera está listo.
Queremos que seas parte de este gran descubrimiento y develación.`,
    additionalSubDescription:
      'Tienes hasta el 29 de septiembre, día de la develación para participar.'
  },
  error: {
    unauthorized: 'No cuentas con permisos suficientes',
    invalid_token:
      'Tu sesión ha expirado o haz superado el limite de tiempo permitido para esta operación',
    user_not_found:
      'Esta cuenta de correo no pertenece a un usuario válido de la aplicación',
    user_not_registered: 'El usuario no fue registrado',
    invalid_credentials: 'El usuario y/o contraseña son incorrectos',
    email_already_exists:
      'El email ya pertence a una cuenta registrada anteriormente. Intenta iniciar sesión.',
    underage_user_not_allowed:
      'Debes ser mayor de edad para usar la aplicación',
    location_already_exists: 'Esta ubicación ya existe',
    location_not_found: 'La ubicación no se encontró',
    unexpected_error: 'Ha ocurrido un error inesperado',
    account_not_verified:
      'Tu cuenta aún no ha sido verificada, revisa tu email para continuar',
    invalid_verification_code: 'El código de verificación no es válido',
    pixel_already_selected:
      'Alguién más ya selecciono este Pixel, debes elegir otro.',
    invalid_pixel: 'Selección inválida',
    user_already_participated_title: '¡Ya participaste!',
    user_already_participated: 'Solo puedes participar una vez en la dinámica.',
    user_already_participated_invite:
      'Invita a tus amigos para seguir participando.'
  },
  promotionDynamics: {
    textOne: '1. Selecciona UN pixel.',
    textTwo: '2. Descubre tu premio.',
    description: `Tu participación nos ayudará a develar la imagen del nuevo mural de
    la F1. Podrás ganar Gorras, hieleras, cupones, o boletos
    para la F1.`
  },
  loserModal: {
    announcement:
      'Gracias por ayudarnos a develar una parte del mural de Heineken® 0.0 en colaboración con Ely Ely para la F1®.',
    title: 'Lo sentimos',
    description: 'El pixel que desbloqueaste no tiene premio.',
    subDescription: 'Sigue al pendiente de otras dinámicas para poder ganar.'
  },
  winnerModal: {
    announcement:
      'Gracias por ayudarnos a develar una parte del mural de Heineken® 0.0 en colaboración con Ely Ely para la F1®.',
    title: '¡Felicidades!',
    description: 'GANASTE 1 ',
    ticket:
      'Revisa el correo con el que te registraste para saber cómo canjear tus entradas para el evento este 30 de octubre.',
    cooler:
      'Revisa el correo con el que te registraste para saber cómo canjearla.',
    cap:
      'Revisa el correo con el que te registraste para saber cómo canjearla.',
    rappi: 'Canjea el código que te llegará al correo en tu siguiente compra.',
    code: 'Código: '
  },
  rewards: {
    f1Ticket: 'Boleto F1',
    cooler: 'Hielera',
    rappi: 'Cupón Rappi',
    cap: 'Gorra'
  },
  modalInstructions: {
    text1:
      'La oportunidad de vivir la experiencia de la F1® con Heineken® 0.0 está a sólo un click de distancia.',
    text2: 'Cada pixel es una oportunidad para ganar.',
    text3: 'Lo único que tienes que hacer es:',
    text4: 'Dar click en el cuadrante',
    text5: 'Seleccionar tu pixel',
    text6: 'Descubre si tu pixel fue ganador.',
    text7:
      'De no ser así, sigue invitando a tus amigos a participar y no pierdas la oportunidad de ganar'
  },
  copyFinish:
    '¡La dinámica ha terminado! Gracias por ser parte de la develación del arte oficial de Heineken® 0.0 en colaboración con Ely Ely para la F1.'
};
