import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    // marginRight: theme.spacing(2),
    height: 240,
    [theme.breakpoints.down('sm')]: {
      height: 180
    }
  },
  platform: {
    borderLeft: '4px solid',
    borderLeftColor: theme.palette.secondary.main,
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

export { useStyles };
