import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    alert: {
      padding: '0px !important',
      backgroundColor: 'red'
    }
  })
);

export { useStyles };
