import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      maxWidth: 500
    },
    link: {
      marginTop: theme.spacing(8),
      color: '#ccffdc',
      fontSize: 14,
      fontWeight: 500,
      marginRight: theme.spacing(1),
      width: '100%',
      '& span': {
        margin: theme.spacing(1)
      },
      '&:hover': {
        cursor: 'pointer',
        color: '#fff'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2)
      }
    },
    legalsLink: {
      textDecoration: 'none',
      color: '#ccffdc',
      '&:hover': {
        color: '#fff'
      }
    },
    caption: {
      fontSize: '0.78em'
    },
    logo: {
      marginBottom: window.screen.width < 768 ? 40 : 0
    },
    footer: {
      paddingBottom: window.screen.width < 768 ? 100 : 50
    }
  })
);

export { useStyles };
