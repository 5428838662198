import { hexToRgba } from '../helpers';

export const getOverrides = (theme: any) => {
  const IS_DARK = theme.palette.mode === 'dark';

  return {
    MuiAppBar: {
      colorDefault: {
        background: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      regular: theme.mixins.toolbar
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: hexToRgba('#FFFFFF', 0.3),
            borderColor: hexToRgba('#FFFFFF', 0.3)
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          backgroundColor: hexToRgba('#FFFFFF', 0.25),
          borderColor: hexToRgba('#FFFFFF', 0.25)
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.disabledBackground
          }
        }
      }
    },
    MuiAutocomplete: {
      paper: {
        backgroundColor: '#00570c'
      }
    },
    MuiButton: {
      contained: {
        '&:hover': {
          boxShadow: 'none'
        }
      },
      outlined: {
        borderColor: theme.palette.grey[300]
      },
      containedPrimary: {
        boxShadow: IS_DARK
          ? theme.customShadows.z1
          : theme.customShadows.primary
      },
      containedSecondary: {
        boxShadow: IS_DARK
          ? theme.customShadows.z1
          : theme.customShadows.secondary
      },
      sizeLarge: {
        height: '48px'
      }
    },
    MuiCard: {
      root: {
        boxShadow: theme.customShadows.z16,
        position: 'relative'
      }
    },
    MuiPopover: {
      paper: {
        boxShadow: theme.customShadows.z16,
        // border:
        //   theme.palette.mode === 'light' &&
        //   `solid 1px ${theme.palette.grey[100]}`,
        overflowX: 'initial',
        overflowY: 'initial'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#004305'
      },
      rounded: {
        borderRadius: theme.shape.borderRadius * 2
      }
    },
    MuiBackdrop: {
      root: {
        background: `linear-gradient(75deg, ${hexToRgba(
          theme.palette.grey[900],
          0.1
        )} 0%, ${hexToRgba(theme.palette.grey[900], 0.9)} 100%)`
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: theme.palette.grey[500]
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: `solid 1px ${hexToRgba(
          theme.palette.grey[IS_DARK ? 100 : 700],
          0.075
        )}`,
        padding: theme.spacing
      }
    },
    MuiBreadcrumbs: {
      ol: {
        flexWrap: 'nowrap',
        overflowX: 'auto'
      }
    },
    MuiListItemText: {
      root: {
        marginBottom: 2
      }
    },
    MuiDialog: {
      paper: {
        padding: theme.spacing * 3,
        marginLeft: theme.spacing,
        marginRight: theme.spacing,
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          padding: theme.spacing * 2
        }
      },
      paperWidthFalse: {
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          maxWidth: `calc(100% - ${theme.spacing * 2}px)`
        }
      }
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        '& > .MuiTypography-h6': {
          letterSpacing: 6,
          textTransform: 'uppercase',
          fontWeight: 'bold',
          textAlign: 'center',
          color: theme.palette.text.primary
        }
      }
    },
    MuiDialogContent: {
      root: {
        ...theme.typography.body2,
        textAlign: 'center',
        padding: 0,
        paddingTop: theme.spacing * 2,
        paddingBottom: theme.spacing * 3
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        padding: 0
      },
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: theme.spacing * 2
        }
      }
    }
  };
};
