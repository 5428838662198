import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bottomText: {
      textAlign: 'center',
      textTransform: 'uppercase',
      letterSpacing: '0.2625rem',
      fontWeight: 'bold',
      color: '#FFFFFF'
    },
    animation: {
      width: 450
    }
  })
);

export { useStyles };
