import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      maxWidth: 500
    },
    link: {
      color: '#ccffdc',
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      '& span': {
        margin: theme.spacing(1)
      },
      '&:hover': {
        cursor: 'pointer',
        color: '#fff'
      }
    },
    legals: {
      position: 'relative',
      columnGap: 20,
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: window.screen.width < 768 ? 100 : 0
    },
    legalsLink: {
      textDecoration: 'none',
      color: '#ccffdc',
      '&:hover': {
        color: '#fff'
      }
    },
    summary: {
      marginTop: 50,
      color: '#ccffdc'
    },
    summaryIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 30
    },
    imgIcon: {
      width: window.screen.width < 768 ? 50 : 80
    },
    iconWrapper: {
      textAlign: 'center'
    },
    iconTitle: {
      fontSize: window.screen.width < 768 ? 12 : 14
    }
  })
);

export { useStyles };
