import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ expanded }: any) => ({
      position: 'absolute',
      right: theme.spacing(1),
      top: 36,
      opacity: expanded ? 1 : 0,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter
      }),
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    })
  })
);

export { useStyles };
