import { useState, useMemo, useEffect, ReactNode } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  createMuiTheme,
  ThemeProvider as MUIThemeProvider,
  Theme
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getItem } from 'utils/persistentStorage';
import { config } from 'providers/config';

import {
  Skins,
  Colors,
  Directions,
  ThemeContext as ThemeContextType
} from 'models/theme';

import { themeConfig } from 'providers/theme/config';
import * as helpers from './helpers';
import { ThemeContext } from './ThemeContext';

interface Props {
  children: ReactNode;
  defaultTheme: Skins;
}

const ThemeProvider = ({ children, defaultTheme }: Props) => {
  const { siteConfig } = config;

  const [theme, setTheme] = useState<Skins>(
    defaultTheme || siteConfig.defaultTheme
  );

  const [direction, setDirection] = useState<Directions>(
    helpers.getDirection(getItem('direction'))
  );

  const [color, setColor] = useState<Colors>(
    helpers.getColor(getItem('color'))
  );

  const [stretch, setStretch] = useState<boolean>(Boolean(getItem('stretch')));

  const prefersDarkMode: boolean = useMediaQuery(
    '(prefers-color-scheme: dark)'
  );
  const browserTheme: Skins = prefersDarkMode ? Skins.dark : Skins.light;
  const mandatoryAppTheme: Skins = siteConfig.allowBrowserTheme
    ? browserTheme
    : theme;

  useEffect(() => {
    const activeTheme: Skins =
      getItem('theme') === Skins.dark ? Skins.dark : Skins.light;

    setTheme(activeTheme || defaultTheme);
  }, [defaultTheme]);

  const contextValue: ThemeContextType = useMemo(
    () => ({
      theme,
      availableSkins: { dark: Skins.dark, light: Skins.light },
      handleChangeTheme: (theme: Skins): void => setTheme(theme),
      handleChangeDirection: (direction: Directions): void =>
        setDirection(direction),
      handleChangeColor: (color: Colors): void => setColor(color),
      handleChangeStretch: (stretch: boolean): void => setStretch(stretch)
    }),
    [theme]
  );

  const materialTheme: Theme = useMemo(() => {
    const selectedSkin =
      mandatoryAppTheme === Skins.light ? Skins.light : Skins.dark;

    const palette: any = {
      ...themeConfig[selectedSkin].palette,
      ...themeConfig.colors[color],
      color,
      type: selectedSkin
    };

    const skin: any = {
      shadows: themeConfig.getShadows(palette),
      customShadows: themeConfig.getCustomShadows(palette),
      ...themeConfig[selectedSkin],
      ...themeConfig.common,
      palette,
      direction,
      stretch
    };

    return createMuiTheme({
      ...skin,
      overrides: themeConfig.getOverrides(skin),
      props: themeConfig.getProps(skin)
    });
  }, [mandatoryAppTheme, direction, color, stretch]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <MUIThemeProvider theme={materialTheme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider };
