export const en = {
  openMenu: 'Open menu',
  openModal: 'Open Modal',
  goToHome: 'Go to Homepage',
  user: 'User',
  page: 'page',
  profile: 'Profile',
  logout: 'Logout',
  changePassword: 'Change Password',
  add: 'Add',
  edit: 'Edit',
  forms: 'Forms',
  tables: 'Tables',
  hooks: 'Hooks',
  install: 'Install',
  installApp: 'Install App',
  cancel: 'Cancel',
  features: 'Features',
  description: 'Description',
  export: 'Export',
  accept: 'Accept',
  updateData: 'Update data',
  save: 'Save',
  search: 'Search',
  select: 'Select...',
  writeYourSearch: 'Write your search',
  associate: 'Associate',
  unexpectedError: 'Error',
  send: 'Send',
  home: 'Home',
  start: 'Start',
  selectPixel:
    'Your chance to experience Heineken® 0.0 and F1® is just one click away.',
  selectPixelStep2: 'Find out which of these pixels will make you win',
  goodLock:
    'Good luck! These are the prizes that you can find inside the pixels',
  congrats: 'Congrats!',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  acceptTermsOfUse: 'I accept terms of use',
  favoriteBeerQuestion: 'What is your favorite beer?',
  whenYouDrinkQuestion: {
    title: 'When do you prefer to drink a Heineken®?',
    option1: 'During UCL',
    option2: 'Watching the F1® Grand Prix',
    option3: 'With friends in a bar/restaurant',
    option4: 'Playing game online'
  },
  httpErrors: {
    error_token_invalid: 'Your session has expired. Please, login again.'
  },
  noData: {
    loading: 'Loading data',
    noDataToShow: 'There is no data to show',
    noResults: 'No results found for this search'
  },
  form: {
    errors: {
      required: 'This field is required',
      email: 'Write a valid email',
      greaterThanOrEqualTo1: 'Must be greater than or equal to 1',
      passwordsDoNotMatches: 'Passwords do not match',
      invalidFormat: 'Invalid format',
      invalidDay: 'Invalid Day',
      invalidMonth: 'Invalid Month',
      invalidYear: 'Invalid Year',
      invalidPhone: 'Teléfono no valido'
    },
    imagePicker: {
      upload: 'Select',
      remove: 'Remove'
    },
    inputs: {
      name: {
        label: 'Name',
        placeholder: 'Write the name'
      },
      fullName: {
        label: 'Name',
        placeholder: 'Write the full name'
      },
      email: {
        label: 'Email',
        placeholder: 'Write the email'
      },
      image: {
        label: 'Select'
      },
      age: {
        label: 'Age',
        placeholder: 'Write your age'
      },
      address: {
        label: 'Address',
        placeholder: 'Write your address'
      },
      password: {
        label: 'Password',
        placeholder: 'Password'
      },
      confirmPassword: {
        label: 'Confirm Password',
        placeholder: 'Confirm Password'
      },
      personalDescription: {
        label: 'Personal description',
        placeholder: 'Write a short personal description'
      },
      location: {
        label: 'Location',
        placeholder: 'Select your City'
      },
      day: {
        label: 'DD',
        placeholder: 'Day'
      },
      month: {
        label: 'MM',
        placeholder: 'Month'
      },
      year: {
        label: 'YYYY',
        placeholder: 'Year'
      },
      verificationCode: {
        label: 'Verification Code',
        placeholder: 'Code'
      },
      phone: {
        label: 'Phone',
        placeholder: '5500000000'
      },
      gender: {
        label: 'Gender',
        placeholder: 'Select your gender'
      }
    }
  },
  routes: {
    appName: 'Heineken - Pixels',
    login: 'Login',
    register: 'Register',
    passwordReset: 'Password Reset',
    error404: 'Page Not Found',
    accountVerify: 'Account Verify'
  },
  language: {
    es: 'Spanish',
    en: 'English'
  },
  theme: {
    changeToDark: 'Change to Dark Mode',
    changeToLight: 'Change to Light Mode'
  },
  sideMenu: {
    miniMode: 'Use mini mode',
    listOfModule: 'List of {{module}}'
  },
  errorPages: {
    unexpected: 'An unexpected error has occurred',
    unexpectedDescription:
      'We are sorry, we will be working on resolving this unexpected error',
    notFound: 'Page not Found',
    notFoundDescription:
      'The page does not exist or the content is no longer available'
  },
  pwaInstaller: {
    installError:
      'Unfortunately, the installation function is not supported by your browser.',
    installWebApp: 'Install Web App',
    description:
      'By installing the application you can access faster without having to open your browser.',
    installSuccess: 'The application was successfully installed on the device.',
    feature01: 'Access from launcher.',
    feature02: 'Log in faster.'
  },
  auth: {
    signIn: 'Login',
    signOut: 'Logout',
    accountVerify: 'Account Verify',
    signTitle: 'Hello welcome!',
    signUp: 'Create Account',
    forgotPassword: 'Forgot my password',
    notHaveAccount: 'Do not have an account?',
    recoverPassword: 'Recover password',
    recoverPasswordInstructions:
      "Don't worry. We will send you an email with the link to change your account password.",
    recoveryPasswordEmailSended:
      'We have sent you an email with the instructions to recover your password.',
    newPassword: 'New Password',
    resetPassword: 'Reset Password',
    resetPasswordSuccess:
      'Your password has been successfully reset, you can now log in.',
    resetPasswordTimeExpired:
      'The time to reset your password has expired, please try again.',
    resetPasswordInstructions: 'Write a new password to access the platform.',
    alreadyHaveAccount: 'Do you already have an account?',
    registerNextSteps:
      'We have sent you an email with the instructions so you can login',
    incompleteData: 'Incomplete data',
    incompleteDataText: 'You must accept the terms of use',
    summary: {
      p1:
        'Be part of the unveiling of the mural F1® Gran Carrera de México 2022!',
      span: 'With Heineken® 0.0 you can win'
    },
    successAccountVerify: 'Your account has been verified, you can now log in'
  },
  intro: {
    title: 'Register, choose your pixel',
    win: 'and Win!',
    description: `Discover Ely Ely's collaboration with Heineken®0.0`,
    subDescription: `The official Heineken® art for Race Sunday is ready.
We want you to be part of this great discovery and unveiling.`,
    additionalSubDescription:
      'You have until September 29, the day of the unveiling, to participate.'
  },
  error: {
    unauthorized: 'You do not have sufficient permissions',
    invalid_token:
      'Your session has expired or you have exceeded the time limit allowed for this operation',
    user_not_found:
      'This email account does not belong to a valid user of the application',
    user_not_registered: 'User was not registered',
    invalid_credentials: 'The username and/or password are incorrect',
    email_already_exists:
      'The email already belongs to a previously registered account. Please try to login.',
    underage_user_not_allowed:
      'You must be of legal age to use the application',
    location_already_exists: 'This location already exists',
    location_not_found: 'The location was not found',
    unexpected_error: 'An unexpected error has occurred',
    account_not_verified:
      'Your account has not yet been verified, check your email to continue.',
    invalid_verification_code: 'The verification code is invalid',
    pixel_already_selected:
      'Someone else already selected this Pixel, you must choose another.',
    invalid_pixel: 'Invalid selection',
    user_already_participated_title: 'You already participated!',
    user_already_participated: 'You can only participate once in the dynamic.',
    user_already_participated_invite:
      'Invite your friends to continue participating.'
  },
  promotionDynamics: {
    textOne: '1. Select ONE pixel.',
    textTwo: '2. Discover your prize.',
    description: `Your participation will help us reveal the image of the new mural of
    the F1. You can Win Caps, Coolers, Coupons, or Tickets
    for F1.`
  },
  loserModal: {
    announcement:
      'Thank you for helping us unveil a part of the Heineken® 0.0 mural in collaboration with Ely Ely for F1®',
    title: 'We are sorry',
    description: 'The pixel you unlocked has no prize.',
    subDescription: 'Keep an eye out for other dynamics in order to win.'
  },
  winnerModal: {
    announcement:
      'Thank you for helping us unveil a part of the Heineken® 0.0 mural in collaboration with Ely Ely for F1®',
    title: 'Congratulations!',
    description: 'YOU WIN 1 ',
    ticket:
      'Revisa el correo con el que te registraste para saber cómo canjear tus entradas para el evento este 30 de octubre.',
    cooler:
      'Check the email with which you registered to find out how to redeem your tickets for the event this October 30.',
    cap:
      'Check the email with which you registered to find out how to redeem it.',
    rappi:
      'Redeem the code that will come to your email on your next purchase.',
    code: 'Code: '
  },
  rewards: {
    f1Ticket: 'F1 Ticket',
    cooler: 'Cooler',
    rappi: 'Rappi coupon',
    cap: 'Cap'
  },
  modalInstructions: {
    text1:
      'The opportunity to live the F1® experience with Heineken® 0.0 is just a click away.',
    text2: 'Every pixel is an opportunity to win.',
    text3: 'The only thing you have to do is:',
    text4: 'Click on the quadrant',
    text5: 'Select your pixel',
    text6: 'Find out if your pixel was a winner.',
    text7:
      'If not, keep inviting your friends to participate and do not miss your chance to win.'
  },
  copyFinish:
    'The dynamic is over! Thank you for being part of the unveiling of the official art of Heineken® 0.0 in collaboration with Ely Ely for F1.'
};
