/* eslint-disable no-throw-literal */
import { get, isObject } from 'lodash';
import { Button, Typography } from '@material-ui/core';
import { useLoader } from 'components/Loader';
import { useModal } from 'components/Modal';
import { toQueryString } from 'utils/http';
import { instance } from 'providers/api';
import { useAuth } from 'views/Auth';
import { useTranslation } from './useTranslation';

interface Props {
  endpoint: string;
  method: 'post' | 'put' | 'get' | 'post' | 'delete' | 'patch';
}

function useApi<U = null>({ endpoint, method }: Props) {
  const { t } = useTranslation();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { actions: authActions } = useAuth();

  type HandleFetch = <T = U>(param?: {
    body?: object;
    urlParam?: string | number;
    queryString?:
      | string
      | {
          [key: string]: string | number | (string | number)[];
        };
    config?: {
      showLoader?: boolean;
      showErrorModal?: boolean;
    };
  }) => Promise<{ code: number; message: string; payload: T }>;

  const handleFetch: HandleFetch = async ({
    body,
    urlParam,
    queryString,
    config: _config
  } = {}) => {
    const config = {
      showLoader: true,
      showErrorModal: true,
      ..._config
    };

    let url = `${endpoint}${urlParam ? `/${urlParam}` : ''}`;

    if (isObject(queryString)) {
      url = `${url}?${toQueryString(queryString)}`;
    }

    try {
      config.showLoader && handleShowLoader(true);

      const response = await instance[method](url, body);

      const hasError = get(response, 'data.code') !== 200;

      if (hasError) {
        throw { response };
      }

      return response.data.payload;
    } catch (error: any) {
      const errorCode = get(error, 'response.data.error', 'unexpected_error');

      if (get(error, 'response.data.code') === 401) await authActions?.logout();

      const modalTitle =
        errorCode === 'user_already_participated'
          ? t('error.user_already_participated_title')
          : t('unexpectedError');

      config.showErrorModal &&
        handleOpenModal({
          title: modalTitle,
          body:
            errorCode === 'user_already_participated' ? (
              <>
                <Typography>{t(`error.user_already_participated`)}</Typography>
                <Typography>
                  {t(`error.user_already_participated_invite`)}
                </Typography>
              </>
            ) : (
              <Typography>{t(`error.${errorCode}`)}</Typography>
            ),
          actionButtons: (
            <Button color="secondary" onClick={handleCloseModal}>
              {t('accept')}
            </Button>
          )
        });

      return get(error, 'response.data');
    } finally {
      config.showLoader && handleShowLoader(false);
    }
  };

  return handleFetch;
}

export { useApi };
