import { Translations } from '../models';

export const defaultTranslations: Translations = {
  accept: 'Aceptar',
  cancel: 'Cancelar',
  delete: 'Eliminar',
  error: 'Error',
  deleteConfirmationTitle: '¿Estas seguro de eliminar este registro?',
  deleteConfirmationMessage: 'Si borras el registro no podrás recuperarlo',
  httpErrorTitle: '¡Algo va mal!',
  httpErrorInstructions:
    'Para más información contacta al administrador del sistema.'
};
