import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { common } from './common';
import { light } from './light';
import { dark } from './dark';
import { getOverrides } from './overrides';
import { getProps } from './props';
import { getShadows, getCustomShadows } from './shadows';

interface ThemeConfig {
  breakpoints: any;
  colors: any;
  common: any;
  light: any;
  dark: any;
  getOverrides: (theme: any) => any;
  getProps: (theme: any) => any;
  getShadows: (pallete: any) => any;
  getCustomShadows: (pallete: any) => any;
}

export const themeConfig: ThemeConfig = {
  breakpoints,
  colors,
  common,
  light,
  dark,
  getOverrides,
  getProps,
  getShadows,
  getCustomShadows
};
