import { createContext, Context } from 'react';
import { defaultTranslations } from './translations/default';
import { ModalProps, ModalContextType } from './models';

export const initialState: ModalProps = {
  title: '',
  show: false,
  body: null,
  actionButtons: null,
  isDismissible: true,
  configProps: {
    maxWidth: 'xs',
    className: '',
    titleClassName: '',
    showDividers: false,
    scroll: 'paper'
  }
};

export const ModalContext: Context<ModalContextType> = createContext({
  modalState: initialState,
  translations: defaultTranslations,
  handleOpenModal: (props: ModalProps) => console.log(props),
  handleCloseModal: () => console.log('Close Modal')
});
