/* eslint-disable no-console */
import ReactDOM from 'react-dom';
import { isDev } from './utils/env';
import { Root } from './Root';
import './index.scss';

if (!isDev()) {
  console.log = () => {};
  //   console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(<Root />, document.getElementById('root'));
