import { toString } from 'lodash';
import { Configuration, Skins } from 'models';

const getSkin = (skin: string | any): Skins =>
  (Skins as any)[skin] || Skins.light;

export const config: Configuration = {
  appId: (process.env.REACT_APP_NAME || '').replace(/@octopy\/react-spa-/, ''),
  siteConfig: {
    languageCode: (process.env.REACT_APP_LANGUAGE_CODE || '').toLowerCase(),
    defaultTheme: getSkin(process.env.REACT_APP_DEFAULT_THEME),
    allowBrowserTheme:
      process.env.REACT_APP_THEME_ENABLE_BROWSER_SUPPORT === 'true'
  },
  endpoints: {
    mainBackendUrl: toString(process.env.REACT_APP_MAIN_BACKEND_URL)
  }
  // firebase: {
  //   apiKey: toString(process.env.REACT_APP_FIREBASE_API_KEY),
  //   authDomain: toString(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN),
  //   projectId: toString(process.env.REACT_APP_FIREBASE_PROJECT_ID),
  //   storageBucket: toString(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET),
  //   messagingSenderId: toString(
  //     process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
  //   ),
  //   appId: toString(process.env.REACT_APP_FIREBASE_APP_ID),
  //   measurementId: toString(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID),
  //   messaggingKeyId: toString(process.env.REACT_APP_FIREBASE_MESSAGING_KEY_ID)
  // }
};
