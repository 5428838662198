import { useState, useMemo, ReactNode } from 'react';
import { defaultTranslations } from './translations/default';
import { ModalProps, ModalContextType, Translations } from './models';
import { ModalContext, initialState } from './ModalContext';

interface Props {
  children: ReactNode;
  translations?: Translations;
}

const ModalProvider = ({
  children,
  translations = defaultTranslations
}: Props) => {
  const [modalState, setModalState] = useState<ModalProps>(initialState);

  const handleUpdateState = (newState: ModalProps): void => {
    setModalState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const handleOpenModal = (config: ModalProps): void =>
    handleUpdateState({
      ...config,
      show: true
    });

  const handleCloseModal = (): void => setModalState(initialState);

  const contextValue: ModalContextType = useMemo(
    () => ({
      modalState,
      translations,
      handleOpenModal,
      handleCloseModal
    }),
    [modalState, translations]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider };
