/* eslint-disable react-hooks/rules-of-hooks,react-hooks/exhaustive-deps */
import { find, forEach, isEmpty, keys, map, size } from 'lodash';
import { useContext, useEffect } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import { getObjectDiff, logDiffs } from './helpers';

interface Props {
  contexts: {
    [x: string]: any;
  };
  config: {
    objectDiffs: boolean;
    arrayDiffs: boolean;
  };
}

const ContextLogger = ({ contexts, config }: Props) => {
  const contextList: Array<string> = keys(contexts);

  if (size(contextList) === 0) return null;

  const allContexts: Array<{ name: string; value: any }> = map(
    contextList,
    (contextName) => ({
      name: contextName,
      value: useContext(contexts[contextName])
    })
  );

  const allPreviousContexts: Array<any> = map(allContexts, (contextData) =>
    usePrevious(contextData)
  );

  useEffect(() => {
    forEach(allContexts, (context) => {
      const previousContext = find(
        allPreviousContexts,
        (prevContext) => prevContext?.name === context.name
      );

      if (!previousContext) return;

      const changes = getObjectDiff(previousContext.value, context.value);
      const hasChanges = !isEmpty(changes);

      if (hasChanges)
        logDiffs({
          name: context.name,
          prevState: changes,
          newState: context.value,
          config
        });
    });
  }, [allPreviousContexts]);

  return null;
};

export { ContextLogger };
