import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      maxWidth: 500
    },
    instructions: {
      color: '#FFF',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    }
  })
);

export { useStyles };
