import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Button } from '@material-ui/core';
// import { logAnalyticsEvent } from 'providers/firebase/analytics';
import { useTranslation } from 'hooks/useTranslation';
import './Error404.scss';

function Error404() {
  const { t } = useTranslation();
  const history = useHistory();
  const handleNavigateToHome = () => history.push('/');

  useEffect(() => {
    // logAnalyticsEvent('404', {
    //   time: new Date().getTime(),
    //   location: history.location
    // });
  }, []);

  return (
    <Container className="container-not-found" maxWidth="xl">
      <div>
        <Typography variant="h1">404</Typography>
        <Typography variant="h5" color="secondary">
          {t('errorPages.notFound')}
        </Typography>
        <Typography component="p" variant="subtitle2">
          {t('errorPages.notFoundDescription')}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNavigateToHome}
        >
          {t('goToHome')}
        </Button>
      </div>
    </Container>
  );
}

export { Error404 };
