import { useTranslation as useTranslationI18n } from 'react-i18next';
// import { en } from '../translations/en';

// export type ValidI18nKey = Leaves<typeof en, 3>;

// type Join<K, P> = K extends string | number
//   ? P extends string | number
//     ? `${K}${'' extends P ? '' : '.'}${P}`
//     : never
//   : never;

// type Prev = [
//   never,
//   0,
//   1,
//   2,
//   3,
//   4,
//   5,
//   6,
//   7,
//   8,
//   9,
//   10,
//   11,
//   12,
//   13,
//   14,
//   15,
//   16,
//   17,
//   18,
//   19,
//   20,
//   ...0[]
// ];

// type Leaves<T, D extends number = 10> = [D] extends [never]
//   ? never
//   : T extends object
//   ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
//   : '';

export const useTranslation = () => {
  const { t, i18n } = useTranslationI18n();

  const translationHandler = (message: string): string => t(message);

  const language = i18n.language.split('-')[0];

  return {
    t: translationHandler,
    changeLanguage: i18n.changeLanguage,
    language: ['en', 'es'].includes(language) ? language : 'es'
  };
};
