import { useCallback } from 'react';
import { loadFull } from 'tsparticles';
import Particles from 'react-tsparticles';
import { useParticles } from './useParticles';

export const ParticlesBg = (props: any) => {
  const { color, maxOpacity, totalParticles } = useParticles();

  const particlesInit = useCallback(async (engine: any) => {
    // console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    // await console.log(container);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        fpsLimit: 60,
        // background: {
        //   color: '#002d00'
        // },
        // interactivity: {
        //   events: {
        //     onClick: { enable: true, mode: 'push' },
        //     onHover: {
        //       enable: true,
        //       mode: 'repulse',
        //       parallax: { enable: false, force: 60, smooth: 10 }
        //     },
        //     resize: true
        //   },
        //   modes: {
        //     push: { quantity: 4 },
        //     repulse: { distance: 200, duration: 0.4 }
        //   }
        // },
        particles: {
          color: { value: color },
          move: {
            direction: 'none',
            enable: true,
            outModes: 'out',
            random: false,
            speed: 2,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 800
            },
            value: totalParticles
          },
          opacity: {
            animation: {
              enable: true,
              speed: 0.05,
              sync: true,
              startValue: 'max',
              count: 1
              //   destroy: 'min'
            },
            value: {
              min: 0.3,
              max: maxOpacity
            }
          },
          shape: {
            type: 'square'
          },
          size: {
            value: { min: 1, max: 8 }
          }
        }
      }}
    />
  );
};
