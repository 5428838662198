import logo from './logo.png';
import jsIcon from './svg/javascript-icon.svg';
import reactIcon from './svg/react-js-icon.svg';
import scssIcon from './svg/sass-icon.svg';
import svgIcon from './svg/sun-icon.svg';
import folderIcon from './svg/file-manager.svg';
import folderOpenIcon from './svg/folder-open-icon.svg';
import imageIcon from './svg/image-icon.svg';
import bracketsIcon from './svg/brackets-icon.svg';
import iconLogo from './svg/icon-logo.svg';
import nameLogo from './logo.png';
import reward1Circle from './rewardIcon3.png';
import reward2Circle from './rewardIcon2.png';
import reward3Circle from './rewardIcon1.png';
import reward4Circle from './rewardIcon4.png';
import f1heineken from './fi-heineken.png';
import imgInstructionsDesktop from './imgInstructionsDesktop.png';
import imgInstructionsMobile from './imgInstructionsMobile.png';
import instructionsStep1 from './modalInstructions/step1.png';
import instructionsStep2 from './modalInstructions/step2.png';
import instructionsStep3 from './modalInstructions/step3.png';
import instructionsSmallGrid from './modalInstructions/gridsmal.png';
import instructionsBigGrid from './modalInstructions/gridcomplete.png';
import instructionsLogos from './modalInstructions/logos.png';
import closeIcon from './closeIcon.png';
import capF1 from './Gorra_F1.png';
import cooler from './HNK_Hielera.png';
import rappiCupon from './rappiCupon.png';
import ticketHeineken from './ticketheineken.png';

const Images = {
  capF1,
  cooler,
  rappiCupon,
  ticketHeineken,
  logo,
  jsIcon,
  reactIcon,
  scssIcon,
  svgIcon,
  folderIcon,
  folderOpenIcon,
  imageIcon,
  bracketsIcon,
  iconLogo,
  nameLogo,
  reward1Circle,
  reward2Circle,
  reward3Circle,
  reward4Circle,
  f1heineken,
  imgInstructionsDesktop,
  imgInstructionsMobile,
  instructionsStep1,
  instructionsStep2,
  instructionsStep3,
  instructionsSmallGrid,
  instructionsBigGrid,
  instructionsLogos,
  closeIcon
};

export { Images };
