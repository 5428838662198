import { createContext, useState, useMemo, Context, ReactNode } from 'react';
import { ParticlesContextType } from 'models';

interface Props {
  children: ReactNode;
}

export const ParticlesContext: Context<ParticlesContextType> = createContext(
  {} as ParticlesContextType
);

export const ParticlesProvider = ({ children }: Props) => {
  const [color, setColor] = useState<string>('#acbf00');
  const [totalParticles, setTotalParticles] = useState<number>(160);
  const [maxOpacity, setMaxOpacity] = useState<number>(0.5);

  const clean = () => {
    setColor('#acbf00');
    setTotalParticles(160);
    setMaxOpacity(0.5);
  };

  const setForWinner = () => {
    setMaxOpacity(0.95);
    setTotalParticles(400);
    setColor('#e1fa02');
  };

  const setForLoser = () => {
    setMaxOpacity(0.6);
    setTotalParticles(100);
    setColor('#eaeaea');
  };

  const contextValue: ParticlesContextType = useMemo(
    () => ({
      color,
      maxOpacity,
      totalParticles,
      setColor,
      setMaxOpacity,
      setTotalParticles,
      setForWinner,
      setForLoser,
      clean
    }),
    [color, maxOpacity, totalParticles]
  );

  return (
    <ParticlesContext.Provider value={contextValue}>
      {children}
    </ParticlesContext.Provider>
  );
};
