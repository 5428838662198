/* eslint-disable react/jsx-props-no-spreading */
import { get, map } from 'lodash';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { useTranslation } from 'hooks/useTranslation';
import { useAuth } from 'views/Auth';
import { routes, errorPageRoute } from './routes';
import { metas } from './metas';
import './Navigation.scss';

function Navigation() {
  const { t } = useTranslation();

  const { auth } = useAuth();

  return (
    <Router>
      <Switch>
        {map(routes, (route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <>
                <Helmet>
                  <title>{t(route.titleMessage)}</title>
                  {get(metas, route.path, []).map((meta) => (
                    <meta {...meta} />
                  ))}
                </Helmet>
                {route.private ? (
                  auth.isLogguedIn ? (
                    <route.layout>
                      <route.component {...props} />
                    </route.layout>
                  ) : (
                    <Redirect to="/login" />
                  )
                ) : (
                  <route.layout>
                    <route.component {...props} />
                  </route.layout>
                )}
              </>
            )}
          />
        ))}

        <Route
          render={(props) => (
            <>
              <Helmet>
                <title>{t(errorPageRoute.titleMessage)}</title>
              </Helmet>
              <errorPageRoute.layout>
                <errorPageRoute.component {...props} />
              </errorPageRoute.layout>
            </>
          )}
        />
      </Switch>
    </Router>
  );
}

export { Navigation };
