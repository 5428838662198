import Lottie from 'react-lottie-player';
import { Box, Typography } from '@material-ui/core';
import { useLoader } from '../Loader';
import { useStyles } from './BasicLoaderStyles';
import AnimationLoader from 'assets/animations/heineken-loader.json';
import './BasicLoader.scss';

interface Props {
  text?: string;
}

const BasicLoader = ({ text }: Props) => {
  const classes = useStyles();
  const { translations } = useLoader();

  return (
    <div className={classes.container}>
      <Lottie
        loop
        play
        animationData={AnimationLoader}
        className={classes.animation}
        speed={1.5}
      />

      <Box mt={0}>
        <Typography variant="h6" className={classes.bottomText}>
          {text || translations.loadingText}
        </Typography>
      </Box>
    </div>
  );
};

export { BasicLoader };
