import { useHistory } from 'react-router-dom';
import { Container, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'hooks/useTranslation';
import './Error.scss';

interface Props {
  clearError: (params?: any) => void;
}

function Error({ clearError }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNavigateToHome = (): void => {
    clearError();

    history.push('/');
  };

  return (
    <Container className="container-not-found" maxWidth="xl">
      <div>
        <Typography variant="h1">Error</Typography>
        <Typography variant="h5" color="secondary">
          {t('errorPages.unexpected')}
        </Typography>
        <Typography component="p" variant="subtitle2">
          {t('errorPages.unexpectedDescription')}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNavigateToHome}
        >
          {t('goToHome')}
        </Button>
      </div>
    </Container>
  );
}

export { Error };
