import { Component, ErrorInfo, ReactNode } from 'react';
import { FullScreenLayout } from 'layouts/FullScreenLayout/FullScreenLayout';
// import { logAnalyticsEvent } from 'providers/firebase/analytics';
import { Error } from 'views/Error/Error';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false
  };

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // logAnalyticsEvent(error.toString(), errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <FullScreenLayout>
          <Error clearError={(): void => this.setState({ hasError: false })} />
        </FullScreenLayout>
      );
    }

    return this.props.children;
  }
}
