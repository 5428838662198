/* eslint-disable no-param-reassign */
import { useState, useMemo, ReactNode } from 'react';
import { DrawerContext, initialState } from './DrawerContext';
import { DrawerProps, DrawerContext as DrawerContextType } from 'models';

interface Props {
  children: ReactNode;
}

const DrawerProvider = ({ children }: Props) => {
  const [drawerState, setDrawerState] = useState<DrawerProps>(initialState);

  const handleUpdateState = (newState: Partial<DrawerProps>) => {
    setDrawerState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const handleOpenDrawer = (config: Partial<DrawerProps>): void => {
    config.show = true;

    handleUpdateState(config);
  };

  const handleCloseDrawer = (): void =>
    setDrawerState({
      ...initialState,
      configProps: { ...drawerState.configProps }
    });

  const contextValue: DrawerContextType = useMemo(
    () => ({
      drawerState,
      handleOpenDrawer,
      handleCloseDrawer
    }),
    [drawerState]
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

export { DrawerProvider };
