import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ expanded }: any) => ({
      justifyContent: 'flex-start',
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(0),
      padding: theme.spacing(1.5),
      paddingLeft: 18,
      marginLeft: 12,
      backgroundColor: hexToRgba(theme.palette.grey[500], expanded ? 0.125 : 0),
      borderRadius: theme.shape.borderRadius * 2,
      display: 'flex',
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shorter
      })
    }),
    textContainer: ({ expanded }) => ({
      opacity: expanded ? 1 : 0,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter
      })
    }),
    avatar: ({ expanded }) => ({
      transform: expanded ? 'none' : 'translateX(-2px) scale(1.2)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter
      })
    })
  })
);

export { useStyles };
