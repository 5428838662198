import { Box } from '@material-ui/core';
import { useTranslation } from 'hooks/useTranslation';
import { useStyles } from './PromoFinishStyles';
import { Images } from 'assets';

const PromoFinish = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box py={4} width={1} textAlign="center">
        <img className={classes.logo} src={Images.f1heineken} alt="reward" />
      </Box>
      <div className={classes.copyfinish}>
        <p>
          <strong>{t('copyFinish')}</strong>
        </p>
      </div>
      <div className={classes.legals}>
        <a
          href="https://prod-ws-heinekenmxf1.azurewebsites.net/Aviso_de_Privacidad_Formula_1.pdf"
          target="_blank"
          className={classes.legalsLink}
        >
          {t('privacyPolicy')}
        </a>
        <a
          href="https://prod-ws-heinekenmxf1.azurewebsites.net/TyCos_F1.pdf"
          target="_blank"
          className={classes.legalsLink}
        >
          {t('termsOfUse')}
        </a>
      </div>
    </div>
  );
};

export { PromoFinish };
