import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      maxWidth: 620
    },
    logo: {
      width: '100%'
    },
    legals: {
      position: 'relative',
      columnGap: 20,
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: window.screen.width < 768 ? 100 : 0,
      paddingTop: 50
    },
    legalsLink: {
      textDecoration: 'none',
      color: '#ccffdc',
      '&:hover': {
        color: '#fff'
      }
    },
    copyfinish: {
      fontSize: window.screen.width < 768 ? 24 : 34,
      textAlign: 'center'
    }
  })
);

export { useStyles };
