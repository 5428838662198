/* eslint-disable no-console */
import { useState, useMemo, ReactNode } from 'react';
import { useApi } from 'hooks';
import { getItem, setItem } from 'utils/persistentStorage';
import {
  ResetPassword,
  Login,
  Register,
  RecoveryPassword,
  Auth,
  AccountVerify
} from 'models/auth';
import { setToken, getToken, removeToken } from '../helpers/token';
import { AuthContext, initialState } from './AuthContext';

interface Props {
  children: ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const token = getToken();
  const session = !!token ? getItem('session') : initialState;
  const [auth, setAuth] = useState<Auth | any>(session);

  const loginService = useApi({
    endpoint: `auth/login`,
    method: 'post'
  });

  const emailVerifyService = useApi({
    endpoint: `auth/email-verify`,
    method: 'post'
  });

  const registerService = useApi({
    endpoint: `auth/register`,
    method: 'post'
  });

  const recoveryPasswordService = useApi({
    endpoint: `auth/reset-password`,
    method: 'post'
  });

  const resetPasswordService = useApi({
    endpoint: `auth/reset-password`,
    method: 'patch'
  });

  const login = async (params: Login): Promise<any> => {
    try {
      const { user, token, error }: any = await loginService({
        body: params
      });

      if (error === 'account_not_verified') {
        return error;
      }

      if (token) {
        const authData: Auth = {
          isLogguedIn: true,
          token: token,
          user: user
        };

        setToken(authData.token);
        setAuth(authData);
        setItem('session', authData);

        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const accountVerify = async (params: AccountVerify): Promise<boolean> => {
    try {
      const { accountVerified }: any = await emailVerifyService({
        body: params
      });

      if (accountVerified) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const register = async (params: Register): Promise<boolean> => {
    try {
      const { user }: any = await registerService({
        body: params
      });

      if (user) {
        // const authData: Auth = {
        //   isLogguedIn: true,
        //   token: token,
        //   user: user
        // };

        // setToken(authData.token);
        // setAuth(authData);
        // setItem('session', authData);

        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const logout = async (): Promise<boolean> => {
    try {
      removeToken();
      setAuth(initialState);
      window.localStorage.removeItem('session');

      return true;
    } catch (error) {
      return false;
    }
  };

  const recoveryPassword = async (
    params: RecoveryPassword
  ): Promise<boolean> => {
    try {
      const { emailSended }: any = await recoveryPasswordService({
        body: params
      });

      if (emailSended) {
        return true;
      }
    } catch (error) {
      //
    }

    return false;
  };

  const resetPassword = async (
    params: ResetPassword,
    token: string
  ): Promise<boolean> => {
    try {
      setToken(token);

      const { passwordReset }: any = await resetPasswordService({
        body: {
          password: params.password
        }
      });

      if (passwordReset) {
        return true;
      }
    } catch (error) {
      //
    }

    return false;
  };

  const contextValue = useMemo(
    () => ({
      auth,
      actions: {
        login,
        register,
        logout,
        resetPassword,
        recoveryPassword,
        accountVerify
      }
    }),
    [auth]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider };
