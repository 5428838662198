import { NavigationMetadata } from 'models/navigation';

export const metas: NavigationMetadata = {
  '/': [
    {
      name: 'description',
      content: 'Home component'
    }
  ]
};
