import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      zIndex: theme.zIndex.drawer + 1,
      '& .MuiDialog-paper': {
        padding: theme.spacing(2)
      }
      // zIndex: `${theme.zIndex.drawer + 1} !important`
    }
  })
);

export { useStyles };
