import { BaseLayout, FullScreenLayout } from 'layouts';
// import { Home } from 'views/Home';
import { Error404 } from 'views/Error404';
import { SimpleRoute, Route } from 'models/navigation';
import { Login, Register, PasswordRecovery, AccountVerify } from 'views/Auth';
import { Intro } from 'views/Intro';
import { Pixels } from 'views/Pixels';
import { PromoFinish } from 'views/PromoFinish';

export const routes: Route[] = [
  // {
  //   path: '/',
  //   layout: BaseLayout,
  //   component: Pixels,
  //   exact: true,
  //   titleMessage: 'routes.appName',
  //   private: true
  // },
  {
    path: '/',
    layout: FullScreenLayout,
    component: PromoFinish,
    exact: true,
    titleMessage: 'routes.appName',
    private: false
  }
  // ,
  // {
  //   path: '/login/:email?/:token?',
  //   layout: FullScreenLayout,
  //   component: Login,
  //   exact: true,
  //   titleMessage: 'routes.login',
  //   private: false
  // },
  // {
  //   path: '/register',
  //   layout: FullScreenLayout,
  //   component: Register,
  //   exact: true,
  //   titleMessage: 'routes.register',
  //   private: false
  // },
  // {
  //   path: '/account-verify/:email?',
  //   layout: FullScreenLayout,
  //   component: AccountVerify,
  //   exact: true,
  //   titleMessage: 'routes.accountVerify',
  //   private: false
  // },
  // {
  //   path: '/reset-password/:token',
  //   layout: FullScreenLayout,
  //   component: PasswordRecovery,
  //   exact: true,
  //   titleMessage: 'routes.passwordReset',
  //   private: false
  // },
  // {
  //   path: '/pixels',
  //   layout: BaseLayout,
  //   component: Pixels,
  //   exact: true,
  //   titleMessage: 'routes.appName',
  //   private: true
  // }
];

export const errorPageRoute: SimpleRoute = {
  layout: FullScreenLayout,
  component: Error404,
  titleMessage: 'routes.404'
};
