import { get } from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.common.white,
      backgroundColor: get(theme, 'palette.common.loaderBackground', '#000000'),
      opacity: '0.95 !important',
      zIndex: theme.zIndex.drawer + 2
    }
  })
);

export { useStyles };
