import { Box, Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { BaseProps, Texts } from './models';
import { useLoader } from './hooks/useLoader';
import { useStyles } from './LoaderStyles';

interface Props extends BaseProps, Texts {}

const Loader = ({ children, topText, bottomText }: Props) => {
  const classes = useStyles();
  const { show, texts } = useLoader();

  const textRender = (text: string | any) =>
    text ? (
      <Box m={4}>
        <Typography variant="h5">{text}</Typography>
      </Box>
    ) : null;

  return (
    <Backdrop open={show || false} className={classes.backdrop}>
      {children ? (
        children
      ) : (
        <>
          {textRender(topText || texts?.topText)}
          <CircularProgress color="inherit" size={56} />
          {textRender(bottomText || texts?.bottomText)}
        </>
      )}
    </Backdrop>
  );
};

export { Loader };
