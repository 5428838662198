/* eslint-disable no-unused-vars */

export enum Skins {
  dark = 'dark',
  light = 'light'
}

export enum Colors {
  blue = 'blue',
  yellow = 'yellow',
  green = 'green',
  pink = 'pink',
  orange = 'orange'
}

export enum Directions {
  ltr = 'ltr',
  rtl = 'rtl'
}

export interface ThemeContext {
  theme: Skins;
  availableSkins: {
    [key in Skins]: Skins;
  };
  handleChangeTheme: (theme: Skins) => void;
  handleChangeDirection: (direction: Directions) => void;
  handleChangeColor: (color: Colors) => void;
  handleChangeStretch: (stretch: boolean) => void;
}
