import { useState, useMemo } from 'react';
import { defaultTranslations } from './translations/default';
import {
  BaseProps,
  LoaderContextType,
  Texts,
  TranslationProps
} from './models';
import { LoaderContext } from './LoaderContext';

interface Props extends BaseProps, TranslationProps {}

const LoaderProvider = ({
  children,
  translations = defaultTranslations
}: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [texts, setTexts] = useState<Texts>({
    topText: '',
    bottomText: ''
  });

  const handleChangeText = ({ topText = '', bottomText = '' }) =>
    setTexts({ topText, bottomText });

  const contextValue: LoaderContextType = useMemo(
    () => ({
      show,
      texts,
      translations,
      handleChangeText,
      handleShowLoader: setShow
    }),
    [show, texts]
  );

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderProvider };
