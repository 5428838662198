import { ModalProvider } from 'components/Modal';
import {
  LoaderProvider,
  defaultTranslations as translationsLoader
} from 'components/Loader';
import { isDev } from 'utils/env';
import { Skins } from 'models/theme';
import { App } from 'components/App';
import { ThemeProvider } from 'components/Theme';
import { SideMenuProvider } from 'components/SideMenu';
import { ContextLogger } from 'components/ContextLogger';
import { DrawerProvider } from 'components/Drawer';
import { ParticlesProvider } from 'components/Particles';
import { AuthProvider } from 'views/Auth';

import appContext from './context';

const contextConfig = { objectDiffs: true, arrayDiffs: false };

const Root = () => (
  <ThemeProvider defaultTheme={Skins.light}>
    <LoaderProvider translations={translationsLoader}>
      <ParticlesProvider>
        <ModalProvider>
          <AuthProvider>
            <SideMenuProvider>
              <DrawerProvider>
                <App />
              </DrawerProvider>

              {isDev() && (
                <ContextLogger contexts={appContext} config={contextConfig} />
              )}
            </SideMenuProvider>
          </AuthProvider>
        </ModalProvider>
      </ParticlesProvider>
    </LoaderProvider>
  </ThemeProvider>
);

export { Root };
